
.box-titre-formulaire, .sous-titre {
    margin-bottom: 2vh;
}

.form-style {
    min-height: 65vh !important;
    padding-bottom: 4vh;
}


.reponse {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.reponse-e {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap : 2vw;
}

.box-reponse-e {
    margin-top: 2vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1vh;
    flex-wrap: wrap;
}

.box-contenu-reponse-e {
    border: 5px solid lightgrey;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 10vw;
    height: 12vh;
}

.texte-contenu-reponse-e {
    font-size: 1vw !important;
    text-align: center;
    height: 11vh;
}

.vrai-faux {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 6vw;
}

.option-texte {
    margin-right: 10px;
}



.box-titre {
    display: flex;
    justify-content: center;
    align-items: center;
}



.question-texte {
    font-size: 1.1vw !important;
}

.reponse-zone-texte {
    width: 80%;
    margin-left: 10vw !important;
    margin-right: 10vw !important;
    margin-top: 2vh !important;
    margin-bottom: 2vh !important;
}

.reponse-zone-texte-petit {
    width: 80% !important;
    font-size: calc(0.7vw + 0.7vh); 
    height: 5.5vh !important;
}

.reponse-zone-texte textarea { /*zone de texte*/
    font-size: calc(0.7vw + 0.7vh); 
}

.reponse-zone-texte-petit textarea { /*zone de texte*/
    font-size: calc(0.7vw + 0.7vh); 
}

.slider-choix {
    width: 60% !important;
    margin: 2%;
}

.question-list-box {
    height: 40vh !important;
}
