.titre-modale{
    font-size: 1.5vw;
    text-align: center;
}
.phrase-modale {
    font-size: 1vw;
}

.box-button-modale
{
    display: flex;
    justify-content: center;
    align-items: center;
}


.box-modale {
    border: 5px solid black;
    border-radius: 2px;
}