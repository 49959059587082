

.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 81vh;
}

.titre-texte {
    margin-top: 4vh !important;
    text-align: center;
    font-size: 1.5vw !important;
} 

.titre {
    text-align: center;
    font-size: 1.5vw !important;
    margin-bottom: 2vh !important;
}

.sous-titre {
    text-align: center;
    font-size: 0.8vw !important;
}

.box-bouton-formulaire {
    margin-top: 7vh;
    bottom: 15vh;
    left: 0;
    right: 0;
    gap: 30vw !important;
    display: flex;
    justify-content: center;
}

.button-action {
    width: 10vw;
    font-size: 1.2vw !important;
}

.button-action-locked {
    width: 10vw;
    font-size: 1.2vw !important;
    background-color: lightgrey !important;
}

body {
    margin: 0 !important;
    border: none !important;
}

.box-formulaire {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}


.form-style {
    border: 5px solid  lightgrey;
    border-radius: 30px;
    margin: 0% 2% 0% 2%;
}

.box-titre-sous-titre {
    display: flex !important;
    flex-direction: column !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;

}

.box-titre-formulaire{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 0%;
    margin-bottom: 5%;
    margin-left: 2% !important;
    margin-right: 2% !important;
}

.box-texte-formulaire {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    margin-left: 10% !important;
    margin-right: 10% !important;
}
