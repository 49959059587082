.tableau-resultat {
    background-color: lightgrey;
    width: 95vw !important;
}

.tableau-section {
    border: 1px solid black !important;
    font-size: 1vw !important; 
    padding-top: 1.30vh !important;
    padding-bottom: 1.30vh !important;
    max-width: 20vw;
}

.texte-centre {
    text-align: center !important;
}

.tableau-numero {
    margin: 1.5vw; 
}

.box-bouton-formulaire-resultat {
    margin-top: 2vh;
    display: flex;
    justify-content: center;
}
