
.button-bienvenue {
    margin-top: 5vh !important;
    margin-bottom: 21vh !important;
    width: 10vw;
    font-size: 1.2vw !important;
}

.box-message {
    margin-top: 150px;
}

.texte-bienvenue {
    margin-top: 50px !important;
    max-width: 900px;
    font-size: 20px !important;
}

.section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

