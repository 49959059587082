

.reponse {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.vrai-faux {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 8vw !important;
}

.option-texte {
    margin-right: 1.5vw !important;
}

.radioButton-vf {
    display: flex;
    justify-content: space-between;
}

.radio-vrai,
.radio-faux {
    margin-right: 2vw !important;
}



.box-sous-titre {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 33%;
    margin-right: 11%;
}

.question-texte-vf {
    max-width: 80%;
    font-size: 1.85vh !important;
}