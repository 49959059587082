.form-box {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.box-img {
    display: flex;
    justify-content: center;
    align-items: center;
}