.titre-texte-concret {
    margin-top: 1vh !important;
    text-align: center;
    font-size: 1.5vw !important;
} 

.box-titre-concret {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

.action-box {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin: 1vw;
    border: 5px solid lightgrey;
}

.box-bordure {
    border: 5px solid lightgrey;
    width: 100%;
}

.box-bordure-max {
    border: 5px solid lightgrey;
    width: 100%;
    height: 37vh;
    max-height: 37vh !important;
    overflow: auto !important;
}

.texte-action-ressource {
    font-size: 0.8vw !important;
    max-width: 35vw;
}

.checkbox-action-ressource {
    transform: scale(1.5);
    color: black !important;
}

.list-action-ressource {
    justify-content: space-around !important;
    border-bottom: 1px solid black;
}
