

.box-border {
    border: 5px solid lightgrey;
  }
  
  .h1 {
    margin-top: 4%;
  }
  
  .img {
    width: 20vw;
    height: 15vw;
  }
  
  .form {
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    width: sm;
  }
  
  .button {
    margin-top: 3rem;
    margin-bottom: 2rem;
  }

  .box-form {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-top: 10px;
  }

  .box-main {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width : 100%;
    margin-top: 50px;
  }