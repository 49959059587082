.header {
    background-color: white;
    border-bottom: 1px solid black;
  }
  
  .logo {
    width: 15%;
    min-width: 125px;
  }
  
  .nav-icon {
    color: black;
  }
  
  .menu-items {
    display: block;
  }

  
  .nav-button {
    color: black !important;
    display: block;
  }
  
  .avatar-tooltip {
    margin-top: 45px;
  }

  .texte-nav-button {
    text-decoration: none; 
    color: black;
    align-items: center;
  }
