.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100vw;
    bottom: 0;
    height: 5vh;
    background-color: #FFFFFF;
    padding: 10px 0;
    border-top: 1px solid #000000;
}
  
.footer .logo {
    width: 60%;
    min-width: 125px;
    margin-left: 5%;
}
  
.footer .copyright {
    text-align: right;
    color: #000000;
    margin-right: 1vw;
}